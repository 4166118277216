.input__container {
	display: flex;
	justify-content: space-evenly;
}

.input__wrapper {
	width: 100%;
	padding: 0 1rem;
}

.input__box {
	text-align: center;
	height: 3rem;
	width: 100%;
	padding: .5rem 1rem;
	border-radius: 9999px;
	border: 5px solid #0d6efd;
}

.button__wrapper {
	padding: 0 1rem;
}

.input__button {
	width: 5rem;
	height: 100%;
	border-radius: 9999px;
	background-color: #0d6efd;
	border: 0;
	padding: 0 0.75rem;
	color: white;
}
