.dividers {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	height: 12rem;
}

.divider0 {
	width: 75%;
	height: 3px;
	margin: 1rem 0;
	background-color: rgb(44, 99, 219);
}

.divider1 {
	height: 3px;
	width: 55%;
	margin: 1rem 0;
	background-color: rgb(64, 110, 209);
}

.divider2 {
	height: 3px;
	width: 35%;
	margin: 1rem 0;
	background-color: rgb(44, 140, 219);
}

.divider3 {
	height: 3px;
	width: 20%;
	margin: 1rem 0;
	background-color: rgb(231, 149, 27);
}

.divider4 {
	height: 3px;
	width: 10%;
	margin: 1rem 0;
	background-color: rgb(234, 129, 9);
}